/**
 * A reimplemention of relay's `usePaginationFragment` hook. Neccessary
 * due to a bug in relay's `useLoadMoreFunction` hook that also required
 * reimplementation. Its features are exposed through `usePaginationFragment`,
 * so we couldn't utilize it without rebuilding `usePaginationFragment`.
 *
 * Original:
 * https://github.com/facebook/relay/blob/v18.0.0/packages/react-relay/relay-hooks/usePaginationFragment.js
 */

import { useCallback, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';
// @ts-expect-error This is bad and we really shouldn't be importing internals,
// but `useRefetchableFragmentInternal` is a fairly complicated hook that is
// the core of `useRefetchableFragment`, and reimplementing would be risky.
import useRefetchableFragmentInternal from 'react-relay/lib/relay-hooks/useRefetchableFragmentInternal';
import { usePaginationFragmentHookType } from 'react-relay/relay-hooks/usePaginationFragment';
import { Options } from 'react-relay/relay-hooks/useRefetchableFragmentNode';
import {
  GraphQLResponse,
  GraphQLTaggedNode,
  Observer,
  OperationType,
  Variables,
  VariablesOf,
  getFragment,
  getFragmentIdentifier,
  getPaginationMetadata,
} from 'relay-runtime';

import { RelayKeyTypeDataExtended, RelayKeyTypeExtended } from '../../utils/relay';

import { LoadMoreFn, UseLoadMoreFunctionArgs, useLoadMoreFunction } from './useLoadMoreFunction';

function usePaginationFragment<
  TFragmentType extends OperationType,
  TKey extends RelayKeyTypeExtended,
  TVariables extends Variables = VariablesOf<TFragmentType>,
  TData = RelayKeyTypeDataExtended<TKey>
>(
  fragmentInput: GraphQLTaggedNode,
  parentFragmentRef: TKey
): usePaginationFragmentHookType<TFragmentType, TKey, TData> {
  const fragmentNode = getFragment(fragmentInput);
  const componentDisplayName = 'usePaginationFragment()';

  const { connectionPathInFragmentData, paginationRequest, paginationMetadata } =
    getPaginationMetadata(fragmentNode, componentDisplayName);

  const { fragmentData, fragmentRef, refetch } = useRefetchableFragmentInternal<
    { variables: TVariables; response: TData },
    { data?: TData }
  >(fragmentNode, parentFragmentRef);
  const fragmentIdentifier = getFragmentIdentifier(fragmentNode, fragmentRef);

  const [loadPrevious, hasPrevious, isLoadingPrevious, disposeFetchPrevious] =
    useLoadMore<TFragmentType>({
      componentDisplayName,
      connectionPathInFragmentData,
      direction: 'backward',
      fragmentData,
      fragmentIdentifier,
      fragmentNode,
      fragmentRef,
      paginationMetadata,
      paginationRequest,
    });

  const [loadNext, hasNext, isLoadingNext, disposeFetchNext] = useLoadMore<TFragmentType>({
    componentDisplayName,
    connectionPathInFragmentData,
    direction: 'forward',
    fragmentData,
    fragmentIdentifier,
    fragmentNode,
    fragmentRef,
    paginationMetadata,
    paginationRequest,
  });

  const refetchPagination = useCallback(
    (variables: Partial<VariablesOf<TFragmentType>>, options?: Options) => {
      disposeFetchNext();
      disposeFetchPrevious();
      return refetch(variables, { ...options });
    },
    [disposeFetchNext, disposeFetchPrevious, refetch]
  );

  return {
    data: fragmentData,
    loadNext,
    loadPrevious,
    hasNext,
    hasPrevious,
    isLoadingNext,
    isLoadingPrevious,
    refetch: refetchPagination,
  };
}

function useLoadMore<TQuery extends OperationType>(
  args: Omit<UseLoadMoreFunctionArgs, 'observer' | 'onReset'>
): [LoadMoreFn<TQuery>, boolean, boolean, () => void] {
  const environment = useRelayEnvironment();
  const [isLoadingMore, setIsReallyLoadingMore] = useState(false);

  const setIsLoadingMore = (value: boolean) => {
    // @ts-expect-error @types/relay-runtime is missing `getScheduler()`,
    // but it definitely exists on the relay environment and its flow types.
    // https://github.com/facebook/relay/blob/v18.0.0/packages/relay-runtime/store/RelayStoreTypes.js#L927
    const schedule = environment.getScheduler()?.schedule;
    if (schedule) {
      schedule(() => {
        setIsReallyLoadingMore(value);
      });
    } else {
      setIsReallyLoadingMore(value);
    }
  };

  const observer: Observer<GraphQLResponse> = {
    start: () => setIsLoadingMore(true),
    complete: () => setIsLoadingMore(false),
    error: () => setIsLoadingMore(false),
  };

  const handleReset = () => setIsLoadingMore(false);
  const [loadMore, hasMore, disposeFetch] = useLoadMoreFunction<TQuery>({
    ...args,
    observer,
    onReset: handleReset,
  });

  return [loadMore, hasMore, isLoadingMore, disposeFetch];
}

export { usePaginationFragment };
