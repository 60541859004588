import { AIGeneratedTextOrigin } from '@attentive/data/types';

import { ProductInfo } from './companyProductOverrides';

import { OpenAIModel } from './__generated__/BrandVoicePreviewGenerateAIJourneyTextMutation.graphql';

export type PreviewTemplateKey =
  | 'CAMPAIGN_BEST_SELLER'
  | 'CAMPAIGN_FLASH_SALE'
  | 'EMAIL_SUBJECT_LINE_ASSISTANT'
  | 'CART_ABANDON_AI_JOURNEY'
  | 'PAGE_VIEW_AI_JOURNEY';

export type MessageInputAvailableData = {
  company: {
    id: string;
    name: string;
  };
  product: ProductInfo & {
    link: string;
  };
  subscriber: {
    firstName: string;
  };
};

export const previewTemplateMap: {
  [K in PreviewTemplateKey]: {
    label: React.ReactNode;
    template: {
      templateName: string;
      version: string;
    };
    getUserRoleInput: (availableData: MessageInputAvailableData) => string;
    model?: OpenAIModel;
    origin?: `${AIGeneratedTextOrigin}`;
  };
} = {
  CAMPAIGN_BEST_SELLER: {
    label: 'Campaign message',
    template: {
      templateName: 'sms_bestseller_bv',
      version: 'live',
    },
    getUserRoleInput: (info) => info.product.link,
    origin: 'GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_SMS',
  },
  CAMPAIGN_FLASH_SALE: {
    label: 'Campaign message',
    template: {
      templateName: 'sms_flash_sale_bv',
      version: 'live',
    },
    getUserRoleInput: (info) => info.product.link,
    origin: 'GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_SMS',
  },
  EMAIL_SUBJECT_LINE_ASSISTANT: {
    label: 'Email campaign subject line',
    template: {
      templateName: 'email_subject_line_bv',
      version: 'live',
    },
    getUserRoleInput: () => '',
    origin: 'GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_EMAIL_SUBJECT_LINE',
  },
  CART_ABANDON_AI_JOURNEY: {
    label: 'Add to cart',
    template: {
      templateName: 'unified_ai_journey_cart_abandon_bv',
      version: 'live',
    },
    getUserRoleInput: (info) => {
      const content = {
        company: {
          name: info.company.name,
        },
        product: {
          name: info.product.name,
          link: info.product.link,
        },
        subscriber: {
          firstName: info.subscriber.firstName,
        },
      };
      return JSON.stringify(content);
    },
  },
  PAGE_VIEW_AI_JOURNEY: {
    label: 'Session',
    template: {
      templateName: 'ai_journeys_session_bv',
      version: 'live',
    },
    getUserRoleInput: (info) => {
      const content = {
        company: {
          name: info.company.name,
        },
        cta: {
          shortLink: info.product.link,
        },
        subscriber: {
          firstName: info.subscriber.firstName,
        },
      };
      return JSON.stringify(content);
    },
  },
};
