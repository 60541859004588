/**
 * A reimplemention of relay's `useLoadMoreFunction` hook. Neccessary
 * due to a bug in relay's handling of pagination requests with deferred
 * fragments. If a consumer attempts to load more data while the parent query
 * is still pending due to a deferred fragment, the load more function will
 * noop and return no data for that request. The main intent of this
 * implementation is to remove the check on the parent query's activity.
 *
 * Original:
 * https://github.com/facebook/relay/blob/e357063c26d0aaf0a5027ba591138ad172cd103f/packages/react-relay/relay-hooks/useLoadMoreFunction_EXPERIMENTAL.js
 */

import { useCallback, useRef, useState, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import {
  ConcreteRequest,
  Direction,
  Disposable,
  GraphQLResponse,
  Observer,
  OperationType,
  ReaderFragment,
  ReaderPaginationMetadata,
  ReaderRefetchMetadata,
  Subscription,
  VariablesOf,
  __internal,
  createOperationDescriptor,
  getPaginationVariables,
  getRefetchMetadata as getRefetchMetadata_RELAY,
  getSelector,
  getValueAtPath,
} from 'relay-runtime';

import { RelayConnectionFields } from '../../utils/relay';

// Necessary to retype because @types/relay-runtime is missing `identifierInfo`.
type GetRefetchMetadata = (
  fragmentNode: ReaderFragment,
  componentDisplayName: string
) => {
  fragmentRefPathInResponse: ReadonlyArray<string | number>;
  identifierInfo?: {
    identifierField: string;
    identifierQueryVariableName: string;
  } | null;
  refetchableRequest: ConcreteRequest;
  refetchMetadata: ReaderRefetchMetadata;
};

const getRefetchMetadata = getRefetchMetadata_RELAY as GetRefetchMetadata;
const { fetchQuery } = __internal;

function getConnectionState(
  direction: Direction,
  fragmentData: unknown,
  connectionPathInFragmentData: ReadonlyArray<string | number>
): {
  cursor: string | null;
  hasMore: boolean;
} {
  const { EDGES, PAGE_INFO, HAS_NEXT_PAGE, HAS_PREV_PAGE, END_CURSOR, START_CURSOR } =
    RelayConnectionFields;
  const connection = getValueAtPath(fragmentData, connectionPathInFragmentData);
  if (connection == null) {
    return { cursor: null, hasMore: false };
  }

  const edges = connection[EDGES];
  const pageInfo = connection[PAGE_INFO];
  if (edges == null || pageInfo == null) {
    return { cursor: null, hasMore: false };
  }

  const cursor =
    direction === 'forward' ? pageInfo[END_CURSOR] ?? null : pageInfo[START_CURSOR] ?? null;

  let hasMore;
  if (direction === 'forward') {
    hasMore = cursor != null && pageInfo[HAS_NEXT_PAGE] === true;
  } else {
    hasMore = cursor != null && pageInfo[HAS_PREV_PAGE] === true;
  }

  return { cursor, hasMore };
}

function useIsMountedRef(): { current: boolean } {
  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}

export type LoadMoreFn<TQuery extends OperationType> = (
  count: number,
  options?: {
    onComplete?: (value: Error | null) => void;
    UNSTABLE_extraVariables?: Partial<VariablesOf<TQuery>> | undefined;
  }
) => Disposable;

export type UseLoadMoreFunctionArgs = {
  direction: Direction;
  fragmentNode: ReaderFragment;
  fragmentRef: unknown;
  fragmentIdentifier: string;
  fragmentData: unknown;
  connectionPathInFragmentData: ReadonlyArray<string | number>;
  paginationRequest: ConcreteRequest;
  paginationMetadata: ReaderPaginationMetadata;
  componentDisplayName: string;
  observer: Observer<GraphQLResponse>;
  onReset: () => void;
};

function useLoadMoreFunction<TQuery extends OperationType>(
  args: UseLoadMoreFunctionArgs
): [
  // Function to load more data
  LoadMoreFn<TQuery>,
  // Whether the connection has more data to load
  boolean,
  // Force dispose function which cancels the in-flight fetch itself, and callbacks
  () => void
] {
  const {
    direction,
    fragmentNode,
    fragmentRef,
    fragmentIdentifier,
    fragmentData,
    connectionPathInFragmentData,
    paginationRequest,
    paginationMetadata,
    componentDisplayName,
    observer,
    onReset,
  } = args;
  const environment = useRelayEnvironment();

  const { identifierInfo } = getRefetchMetadata(fragmentNode, componentDisplayName);
  const identifierValue =
    identifierInfo?.identifierField != null &&
    fragmentData != null &&
    typeof fragmentData === 'object'
      ? (fragmentData as { [k: string]: unknown })[identifierInfo.identifierField]
      : null;

  const fetchStatusRef = useRef<
    { kind: 'fetching'; subscription: Subscription } | { kind: 'none' }
  >({ kind: 'none' });
  const [mirroredEnvironment, setMirroredEnvironment] = useState(environment);
  const [mirroredFragmentIdentifier, setMirroredFragmentIdentifier] = useState(fragmentIdentifier);

  const forceDisposeFn = useCallback(() => {
    if (fetchStatusRef.current.kind === 'fetching') {
      fetchStatusRef.current.subscription.unsubscribe();
    }
    fetchStatusRef.current = { kind: 'none' };
  }, []);

  const shouldReset =
    environment !== mirroredEnvironment || fragmentIdentifier !== mirroredFragmentIdentifier;
  if (shouldReset) {
    forceDisposeFn();
    onReset();
    setMirroredEnvironment(environment);
    setMirroredFragmentIdentifier(fragmentIdentifier);
  }

  const { cursor, hasMore } = getConnectionState(
    direction,
    fragmentData,
    connectionPathInFragmentData
  );

  const isMountedRef = useIsMountedRef();
  const loadMore = useCallback(
    (
      count: number,
      options: void | {
        UNSTABLE_extraVariables?: Partial<VariablesOf<TQuery>>;
        onComplete?: (value: Error | null) => void;
      }
    ) => {
      const onComplete = options?.onComplete;
      if (isMountedRef.current !== true) {
        // Bail out and warn if we're trying to paginate after the component
        // has unmounted
        return { dispose: () => {} };
      }

      const fragmentSelector = getSelector(fragmentNode, fragmentRef);
      if (fetchStatusRef.current.kind === 'fetching' || fragmentData == null) {
        if (onComplete) {
          onComplete(null);
        }
        return { dispose: () => {} };
      }

      // Relay can only grab fragment info off singular reader selectors,
      // so throw an error if we somehow have a PluralReaderSelector (this
      // shouldn't happen but is necessary to narrow types for typescript).
      if ('selectors' in fragmentSelector) {
        throw new Error('Expected a SingularReaderSelector but found a PluralReaderSelector');
      }

      const parentVariables = fragmentSelector.owner.variables;
      const fragmentVariables = fragmentSelector.variables;
      const extraVariables = options?.UNSTABLE_extraVariables;
      const baseVariables = {
        ...parentVariables,
        ...fragmentVariables,
      };
      const paginationVariables = getPaginationVariables(
        direction,
        count,
        cursor,
        baseVariables,
        { ...extraVariables },
        paginationMetadata
      );

      // If the query needs an identifier value ('id' or similar) and one
      // was not explicitly provided, read it from the fragment data.
      if (identifierInfo != null) {
        paginationVariables[identifierInfo.identifierQueryVariableName] = identifierValue;
      }

      const paginationQuery = createOperationDescriptor(paginationRequest, paginationVariables, {
        force: true,
      });
      fetchQuery(environment, paginationQuery).subscribe({
        ...observer,
        start: (subscription) => {
          fetchStatusRef.current = { kind: 'fetching', subscription };
          observer.start && observer.start(subscription);
        },
        complete: () => {
          fetchStatusRef.current = { kind: 'none' };
          observer.complete && observer.complete();
          onComplete && onComplete(null);
        },
        error: (error: Error) => {
          fetchStatusRef.current = { kind: 'none' };
          observer.complete && observer.complete();
          onComplete && onComplete(error);
        },
      });
      return {
        dispose: () => {},
      };
    },
    // We disable react-hooks-deps warning because all values inside
    // paginationMetadata are static.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      environment,
      identifierValue,
      direction,
      cursor,
      fragmentData,
      fragmentNode.name,
      fragmentRef,
      componentDisplayName,
    ]
  );
  return [loadMore, hasMore, forceDisposeFn];
}

export { useLoadMoreFunction };
