import { KeyType } from 'react-relay/relay-hooks/helpers';
import { RequestDescriptor, PageInfo, ConnectionInterface } from 'relay-runtime';

// The types exported from @types/react-relay seem out of date. The fields it
// lists are actually undefined, whereas other fields that do exist on a
// fragment ref are missing. See correct list here:
// https://github.com/facebook/relay/blob/de6594bf3e8443268662e45cf7a704fdab541e87/packages/react-relay/relay-hooks/QueryResource.js#LL105
//
// Extending the incorrect type though in hopes it's eventually corrected and
// so that it'll match with other relay functions that are using KeyType.
export type RelayKeyTypeExtended<TData = unknown> = KeyType<TData> &
  Partial<{
    __fragmentOwner: RequestDescriptor;
  }>;

export type RelayKeyTypeDataExtended<
  TKey extends RelayKeyTypeExtended<TData>,
  TData = unknown
> = Required<TKey>[' $data'];

// See: https://relay.dev/graphql/connections.htm#sec-Edge-Types
type RelayEdge<T = unknown> = {
  cursor: string;
  node: T;
};

// A newer version of @types/relay-runtime introduced more general types to
// connection field names. Because we want to create a more formal
// RelayConnection type, we need literal types. So, we're copying over the
// previous type values that relay uses internal. This doesn't matter at
// runtime because consumers should be utilizing `RelayConnectionFields` as an
// enum, whose actual and correct values are supplied via relay's interface.
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/64435
const RelayConnectionFields = ConnectionInterface.get() as {
  CURSOR: 'cursor';
  EDGES: 'edges';
  END_CURSOR: 'endCursor';
  HAS_NEXT_PAGE: 'hasNextPage';
  HAS_PREV_PAGE: 'hasPreviousPage';
  NODE: 'node';
  PAGE_INFO: 'pageInfo';
  PAGE_INFO_TYPE: 'PageInfo';
  START_CURSOR: 'startCursor';
};

// There isn't a formal type in relay for connections and their `pageInfo` field
// https://github.com/facebook/relay/blob/6e4ff95b9e1d53254a0d01a97ab02a53643c0bb4/packages/relay-runtime/handlers/connection/ConnectionInterface.js#L68
export type RelayConnection<T = unknown> = {
  [RelayConnectionFields.EDGES]: ReadonlyArray<RelayEdge<T>> | null;
  [RelayConnectionFields.PAGE_INFO]: Partial<PageInfo> | null;
  totalCount?: number | null;
};

export { RelayConnectionFields };
